import React, { useState, useCallback } from 'react';
import FormWrapper from '../components/FormWrapper';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';

const Contact = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async (event) => {
    event.preventDefault(); // Prevent the page from refreshing when submitting the form
    setIsSubmitting(true);

    // Google ReCaptcha v3 Logic
    if (!executeRecaptcha) {
      setIsError(true);
      return;
    }
    const token = await executeRecaptcha('submitContactForm');

    // The information sent to the /emails endpoint of our API Gateway in AWS
    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      subject: event.target.subject.value,
      content: event.target.content.value,
      recaptchaToken: token
    };

    try {
      // eslint-disable-next-line
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/emails`, formData);
      console.log(response);
      if (response.status === 200)
        setIsSubmitted(true);
      else
        setIsError(true);
      
    } catch (error) {
      setIsError(true);
    } finally {
      setIsSubmitting(false);
    }
  }, [executeRecaptcha]);

  if (isError) {
    return (
      <div className="container my-5">
        <h2>Contact Form</h2>
        <p>There was an error submitting your message. Please email us directly at <strong>support@altavisionsystems.com</strong> with your request.</p>
      </div>
    );
  }

  if (isSubmitted) {
    return (
      <div className="container my-5">
        <h2>Contact Form</h2>
        <p>Thank you for contacting Alta Vision Systems. We have received your message and will get back to you shortly.</p>
      </div>
    );
  }

  return (
    <div className="container my-5">
      <h2>Contact Form</h2>
      <p>Thank you for your interest in Alta Vision Systems. For inquiries or any specific requests, please use the contact form below.</p>
      <FormWrapper>
        <form onSubmit={handleReCaptchaVerify}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Name</label>
            <input type='text' className="form-control" id="name" placeholder="Enter your name here" required />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email address</label>
            <input type="email" className="form-control" id="email" placeholder="Enter your email here" required />
            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
          </div>
          {/* <div className="mb-3">
            <label htmlFor="phone" className="form-label">Phone Number</label>
            <input type='tel' className="form-control" id="phone" placeholder="Enter your phone number here" required />
          </div> */}
          <div className="mb-3">
            <label htmlFor="subject" className="form-label">Subject</label>
            <input type='text' className="form-control" id="subject" placeholder="Enter the email's subject here" required />
          </div>
          <div className="mb-3">
            <label htmlFor="content" className="form-label">Message</label>
            <textarea className="form-control" id="content" rows="3" placeholder="Type your message here"  required></textarea>
          </div>
          <div className='text-end'>
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
          </div>
        </form>
      </FormWrapper>
    </div>
  );
}

export default Contact;