import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';

import App from './App';
import Home from './routes/Home';
import Services from './routes/Services';
import About from './routes/About';
import Contact from './routes/Contact';
import MainLayout from './components/MainLayout';
// import DashboardLayout from './components/DashboardLayout';
// import ClientList from './routes/ClientList';
// import ClientCreate from './routes/ClientCreate';
// import ClientRead from './routes/ClientView';
// import VisionSystemList from './routes/VisionSystemList';
// import VisionSystemCreate from './routes/VisionSystemCreate';
// import VisionSystemRead from './routes/VisionSystemView';
// import ProtectedRoute from './components/ProtectedRoute';
import ErrorPage from './error-page';

import { Amplify } from 'aws-amplify';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: process.env.REACT_APP_CLIENT_ID,
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: 'code', // 'code' | 'link'
      // loginWith: {
      //   // OPTIONAL - Hosted UI configuration
      //   oauth: {
      //     domain: 'your_cognito_domain',
      //     scopes: [
      //       'phone',
      //       'email',
      //       'profile',
      //       'openid',
      //       'aws.cognito.signin.user.admin'
      //     ],
      //     redirectSignIn: ['http://localhost:3000/dashboard'],
      //     redirectSignOut: ['http://localhost:3000/home'],
      //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      //   }
      // }
    }
  }
});

// You can get the current config object
//const currentConfig = Amplify.getConfig();


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <MainLayout />,
        children: [
          { path: "/", element: <Home /> },
          { path: "services", element: <Services /> },
          { path: "about", element: <About /> },
          { path: "contact", element: (
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
              <Contact /> 
            </GoogleReCaptchaProvider>
          )},
        ],
      },
      // {
      //   path: "/dashboard",
      //   element: (
      //     <ProtectedRoute>
      //       <DashboardLayout />
      //     </ProtectedRoute>
      //   ),
      //   children: [
      //     { path: "clients", element: <ClientList /> },
      //     { path: "clients/new", element: <ClientCreate /> },
      //     { path: "clients/:clientId", element: <ClientRead /> },
      //     { path: "visionsystems", element: <VisionSystemList /> },
      //     { path: "visionsystems/new", element: <VisionSystemCreate /> },
      //     { path: "visionsystems/:visionSystemId", element: <VisionSystemRead /> },
      //   ],
      // },
    ],
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();