import React from 'react';

const Services = () => {
  return (
    <>
      {/* Services Section */}
      <section>
        <div className="container my-5">
          <h2>Services</h2>
          <p>
            We use state of the art technology and vision systems from Cognex to design and integrate the right system for any job.
            Whether it be simple presence sensing, metrology, or a complete visual analysis of parts or assemblies, our expert team of integrators work with the client to fulfill the direct needs of the system at hand.
          </p>
        </div>
      </section>

      <hr className="container" />

      {/* Machine Vision Systems Section */}
      <section>
        <div className="container my-5">
          <div className='row'>
            <div className='col-md-6'>
              <h2>Machine Vision Systems</h2>
              <p>
                As a Cognex Partner System Integrator, we have the knowledge and training on today's most state of the art vision systems.
                We specialize in lighting and optics, 3D/2D visual sensors, barcode readers, laser profilers, and much more. We distribute and integrate the following Cognex vision systems:
              </p>
              <ul>
                <li>Vision Pro®</li>
                <li>In-Sight®</li>
              </ul>
              <p>
                We also specialize in camera mounting, performance and feasibility studies, and training.
              </p>
            </div>
            <div className='col-md-6'>
              <img className="img-fluid rounded" width={500} src="cognex-deep-learning.png" alt="deep learning" />
            </div>
          </div>
        </div>
      </section>

      <hr className="container" />

      {/* Custom Software Section */}
      <section>
        <div className="container my-5">
          <h2>Custom Software</h2>
          <p>
            Our team of integrators and software developers are experienced in the development of custom software to cater to the client-specific requirements at hand.
            We can develop custom graphical user interfaces, data acquisition software, and closed loop software providing process feedback. We also perform SPC software integration, PLC and machine integration.
          </p>
        </div>
      </section>
    </>
  );
}

export default Services;