import React from 'react';

// This component will wrap its children with a specific layout and styling
const FormWrapper = ({ children }) => {
  return (
    <>
      <div className='border rounded p-4 bg-white shadow-sm'>
        {children}
      </div>
    </>
  );
};

export default FormWrapper