import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

const MainLayout = () => {
  return (
    <div className='d-flex flex-column vh-100'>
      <header className="sticky-top bg-white shadow">
        <Header />
      </header>
      <main className='flex-grow-1 bg-body-tertiary'>
        <Outlet /> {/* This will render the child routes */}
      </main>
      <footer className="py-3 mt-auto bg-white shadow">
        <Footer />
      </footer>
    </div>
  );
};

export default MainLayout;