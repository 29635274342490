import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="container">
      <div className="row d-flex flex-wrap align-items-center">
        <div className="col-md-6">
          <p className="m-auto text-center text-md-start text-body-secondary">© 2023 Alta Vision Systems. All Rights Reserved.</p>
        </div>
        <div className="col-md-6">
          <ul className="nav justify-content-center justify-content-md-end">
            <li className="nav-item">
              <NavLink className="nav-link text-body-secondary" to="/">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-body-secondary" to="/services">Services</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-body-secondary" to="/about">About</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-body-secondary" to="/contact">Contact</NavLink>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="mt-3 pt-3 border-top">
          <p className="text-body-secondary">
            Checkpoint, Checkpoint II, Vision Pro, and In-Sight are registered trademarks of the Cognex Corporation.
            The ‘Cognex’ logo is the property of Cognex Corporation. Reprinted with permission. Photographs are the property of Cognex Corporation. Reprinted with permission.
          </p>
        </div> */}
    </div>
  );
}

export default Footer;