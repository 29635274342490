import { Outlet } from "react-router";
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';


Amplify.configure(Amplify.getConfig());

export default function App() {
  return (
    <Authenticator.Provider>
      <Outlet />
    </Authenticator.Provider>
  );
}