import React from 'react';

const About = () => {
  return (
    <>
      <section>
        <div className="container my-5">
          <div className="row mb-5">
            <div className="col">
              <h2>About</h2>
              <p>
                Formed in 1998, Alta Vision Systems is a full service Machine Integrator specializing in Cognex, Cognex Sensor and PC-based systems.
                With over 150 Machine Vision Inspection Systems installed, we believe in delivering to the customer robust and accurate inspection systems on time and on budget.
              </p>
            </div>
            <div className="col">
              <img className="img-fluid rounded shadow me-3" width={290} src="avs-outside.png" alt="avs outside" />
              <img className="img-fluid rounded shadow" width={320} src="avs-inside.png" alt="avs inside" />
            </div>
          </div>
        </div>
      </section>
      <hr className='container' />
      <section>
        <div className="container mb-5">
          <h2>Testimonials</h2>
          <div className="row gy-3">
            {/* Testimonial 1 */}
            <div className="col-md-6">
              <div className="card shadow">
                <div className="card-body">
                  <h5 className="card-title">Tim Rourke, Vision Technical Molding</h5>
                  <hr className="my-2" />
                  <p className="card-text">
                    “We rely on vision systems for a variety of critical operations. We need them to work reliably 24/7.
                    Alta Vision Systems has demonstrated the depth of experience one would expect from the industry veterans that they are.
                    Whether as a proof-of-concept mock-up, a mold-protect system, or as a full production inspection system with a custom front-end, Alta Vision Systems has delivered for us again and again.”
                  </p>
                </div>
              </div>
            </div>
            {/* Testimonial 2 */}
            <div className="col-md-6">
              <div className="card shadow">
                <div className="card-body">
                  <h5 className="card-title">Aaron Camp, Chase Machine</h5>
                  <hr className="my-2" />
                  <p className="card-text">
                    "Our experience working with Alta Vision Systems has been exceptional.  The attention given in understanding our application,
                    and testing the various camera/lighting systems to find the combination best suited for that application,
                    proved to be invaluable in providing a vision solution that satisfied the requirements of both ourselves and our customer."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;