import React from 'react';

const Home = () => {
  return (
    <>
      {/* Machine Vision Integrators Section */}
      <section>
        <div className="container-fluid home-background">
          <div className="container text-white text-border" style={{ paddingTop: '6rem', paddingBottom: '6rem' }}>
            <h1>Machine Vision Integrators</h1>
            <div className='fs-4 my-3'>
              <p>
                With over 20 years of Machine Vision experience, we are equipped to support you in all your vision project needs
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Machine Vision Integrators</h2>
              <p>
                Alta Vision Systems is a full-service Machine Vision Integration company based in Westbrook, CT.
              </p>
              <p>
                We specialize in specification, design, installation, validation, maintenance, and repair of new or existing vision systems.
              </p>
              <p>
                With over 20 years of Machine Vision experience, we are equipped to support you in all your vision project needs.
              </p>
            </div>
            <div className="col-md-6">
              <img className="img-fluid" src="https://static.wixstatic.com/media/cc8192_66f111da70f045cbab138c0090324c75~mv2.png/v1/crop/x_82,y_0,w_869,h_562/fill/w_530,h_330,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/in-sight-7000-carousel-3.png" alt="cognex cameras" />
            </div>
          </div>
        </div>
      </section> */}

      {/* <hr className="container" /> */}

      {/* Camera Inspection Systems Section */}
      <section>
        <div className="container my-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h3>Camera Inspection Systems</h3>
              <p>
                Alta Vision Systems is a full-service Machine Vision Integration company based in Westbrook, CT.
                We specialize in specification, design, installation, validation, maintenance, and repair of new or existing vision systems.
              </p>
              <ul>
                <li>Lighting and Optics</li>
                <li>Camera Mounts</li>
                <li>Custom Graphical User Interfaces</li>
                <li>Custom Data Acquisition Software</li>
                <li>Closed-loop Software Providing Process Feedback</li>
                <li>SPC software Integration</li>
                <li>Feasibility Studies</li>
                <li>PLC and Machine Integration</li>
                <li>Training</li>
              </ul>
            </div>
            <div className="col-md-6">
              <img className="img-fluid rounded" src="in-sight-7000-carousel-3.png" alt="cognex cameras" />
            </div>
          </div>
        </div>
      </section>

      <hr className="container" />

      {/* Cognex System Integrator Section */}
      <section>
        <div className="container my-5">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-6">
              <h3>Custom Software</h3>
              <ul>
                <li className='my-1'>Recording and control of sensors, 2D/3D readers, and Vision Systems</li>
                <li className='my-3'>Interface with RS232, Keyboard Wedges, Ethernet, Analog, or Digital Systems</li>
                <li className='my-3'>Ergonomic and powerful user interfaces</li>
                <li className='my-3'>Common database support</li>
                <li className='my-3'>Closed-loop software providing process feedback</li>
                <li className='my-3'>Complete PC based control systems including motion control</li>
                <li className='my-3'>.NET, C#, Visual Basic, JavaScript, Python</li>
              </ul>
            </div>
            <div className="col-md-6">
              <img className="img-fluid rounded" src="cognex-vidi.png" alt="cognex cameras" />
            </div>
          </div>
        </div>
      </section>

      <hr className="container" />

      {/* Cognex System Integrator Section */}
      <section>
        <div className="container my-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h3>Cognex System Integrator</h3>
              <p>
                Alta Vision Systems is a Partner System Integrator of all Cognex equipment.
              </p>
            </div>
            <div className="col-md-6 text-end">
              <img className="img-fluid rounded w-50" src="cognex.png" alt="cognex logo" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;